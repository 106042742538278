import React, { useState, useEffect, useContext } from 'react';
import firebase from "../fbConfig"

export const AuthContext = React.createContext()

export function AuthProvider ({children}) {
  const [current, setCurrent] = useState(null)
  const [loading, setLoading] = useState(true)
  

  const authListener = () => {
    const unsub = firebase.auth().onAuthStateChanged(user => {
  setCurrent(user)
  setLoading(false)
    })
    return unsub
  }
  
  useEffect(() => {
    return authListener()
  }, [])

  const value = {
    current,
  }
  return ( 
    <AuthContext.Provider value={ value }>
      {!loading && children}
    </AuthContext.Provider>
   );
}
