import * as firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
 
const config = {
  apiKey: "AIzaSyAjs6BQNC7EyvwxhTcMwgeE6cSe6XvyXwQ",
  authDomain: "nomad-36cd3.firebaseapp.com",
  databaseURL: "https://nomad-36cd3.firebaseio.com",
  projectId: "nomad-36cd3",
  storageBucket: "nomad-36cd3.appspot.com",
  messagingSenderId: "678000647376",
  appId: "1:678000647376:web:5d64b215cb9a503f9a52d5",
  measurementId: "G-B3KDB90QPH",
  }
  // Initialize Firebase
  //firebase.analytics();
  firebase.initializeApp(config)
  firebase.firestore()
 firebase.storage()
  export default firebase

  // service firebase.storage {
  //   match /b/{bucket}/o {
  //     match /{allPaths=**} {
  //       allow read, write: if request.auth != null;
  //     }
  //   }
  // }