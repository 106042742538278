import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  LandingPageScreen,
  NotFoundScreen,
  StudentProfileScreen,
  StudentRegistrationScreen,
  TeacherProfileScreen,
  TeacherRegistrationScreen,
  StudentLoginScreen,
  TeacherLoginScreen,
  StudentLessonsScreen,
  TeacherLessonsScreen,
  StudentHomeScreen,
  TeacherHomeScreen,
  StudentMessagesScreen,
  TeacherMessagesScreen
} from "../../screens";
import { AuthProvider } from "../auth-connect/ConnectRoutes";
import { StudentRoute } from "../auth-student/StudentRoute"
import { TeacherRoute } from "../auth-teacher/TeacherRoute"

export const Navigation = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
      <Switch>
        <Route
          exact
          path="/"
          component={LandingPageScreen}
          />
        <TeacherRoute
          exact
          //   redirectIfAuthenticated
          path="/teacher-home"
          component={TeacherHomeScreen}
        />
        <TeacherRoute
          exact
          path="/teacher-lessons"
          component={TeacherLessonsScreen}
        />
        <Route
          exact
          //   isProtected
          path="/teacher-login"
          component={TeacherLoginScreen}
        />
        <TeacherRoute
          exact
          path="/teacher-messages"
          component={TeacherMessagesScreen}
        />
        <TeacherRoute
          exact
          path="/teacher-profile"
          component={TeacherProfileScreen}
        />
        <Route
          exact
          path="/teacher-registration"
          component={TeacherRegistrationScreen}
        />
        <StudentRoute
          exact
          path="/student-home"
          //   redirectIfAuthenticated
          component={StudentHomeScreen}
        />
        <StudentRoute
          exact
          path="/student-lessons"
          component={StudentLessonsScreen}
        />
        <Route
          exact
          //   isProtected
          path="/student-login"
          component={StudentLoginScreen}
        />
        <StudentRoute
          exact
          path="/student-messages"
          component={StudentMessagesScreen}
        />
        <StudentRoute
          exact
          path="/student-profile"
          component={StudentProfileScreen}
        />
        <Route
          exact
          path="/student-registration"
          component={StudentRegistrationScreen}
        />
        <Route
          path="*"
          component={NotFoundScreen}
        />
      </Switch>
    </AuthProvider>
    </BrowserRouter>
  );
};
