import React from 'react';
import { Navigation } from "./new_components/navigation/Navigation"
// import { MenuBar } from "./components/menu"

function App() {
  return (
   <> 
    {/* <MenuBar /> */}
    <Navigation />
   </>
  );
}

export default App;
