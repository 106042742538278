import React, { useContext } from 'react';
import {Route, Redirect } from "react-router-dom"
import {AuthContext} from "../auth-connect/ConnectRoutes"

export const StudentRoute = ({component: Component, ...rest}) => {
  const {current} = useContext(AuthContext)
  return (  
    <Route 
    {...rest}
    render={props =>{
    return current ? (
      <Component {...props} />
    ) : 
      <Redirect to={"/student-login"} />
    }}
    />
  );
}
 