import React, { useEffect,useState, useRef } from "react";
import {
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  Icon,
  Avatar,
  Button,
  Divider,
  Typography,
  Backdrop,
  Modal, 
  makeStyles, 
  createMuiTheme
} from "@material-ui/core";
import GoogleMapReact from 'google-map-react';
import { GOOGLE_TOKEN } from '../../token'
import { lightBlue, grey } from "@material-ui/core/colors";
import { v4 as uuidv4 } from "uuid";
import useSupercluster from "use-supercluster";
import userEvent from "@testing-library/user-event";
import firebase from "../fbConfig";
import axios from "axios";
import { useCollection } from "react-firebase-hooks/firestore";
import  "./LandingPage.css"
import { Link } from "react-router-dom"
import { handleLessonConfirmation } from '../functions/index'
import { useSpring, animated } from 'react-spring/web.cjs';
import PropTypes from 'prop-types';
import { Controls } from '../control_components/index'
// import mapboxgl from "mapbox-gl";
// import teacherList from "../../students.json";
// import ReactMapGL, {
//   Marker,
//   FlyToInterpolator,
//   GeolocateControl,
//   Popup,
// } from "react-map-gl";
// import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
// import { MAPBOX_TOKEN } from "../../mapbox";



const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 12,
    color: "white",
    backgroundColor: "#2d60f8"
  },
  default: {
    color: "white",
    backgroundColor: "#2d60f8"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 2),
  }
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

const getUrl = (address, zip) => {
  return `https://maps.googleapis.com/maps/api/geocode/json?address=${address.split(" ").join("+")}+${zip}&key=${GOOGLE_TOKEN}`
  // `https://api.mapbox.com/geocoding/v5/mapbox.places/${address.split(" ").join("&")}&${zip}.json?access_token=${GOOGLE_TOKEN}`;
  // pk.eyJ1Ijoia2V2aW53aWxzb25ncm92ZSIsImEiOiJja2Z3bWM3bDExNG9nMnRwOTl5aDE2YjdxIn0.2XjfbV4Quz5RhSI9u2IAaw
};

const db = firebase.firestore();

export const LandingPage = () => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  

  const [coords, setCoords] = useState({
    lat: 39.83,
    lng: -86.154694
  });
  const [viewport, setViewport] = useState({
    lat: coords.lat,
    lng: coords.lng,
    width: "100%",
    height: "100%",
    zoom: 9.5,
  });
  const [mapPoints, setMapPoints] = useState([]);


  const [currentStudent, setCurrentStudent] = useState({});
  const [selectedTeacher, setSelectedTeacher] = useState(null);



  
  useEffect(() => {
    // if (firebase.auth().currentUser.email !== null) {
      // const studentEmail = firebase.auth().currentUser.email;
      db.collection("InstructorInfo")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.map((doc) => {
            // console.log(doc.data());
            // console.log(doc.id);
            // let confirmed = null;
            // db.doc(`InstructorInfo/${doc.id}`)
            //   .collection("LessonConfirmations")
            //   .doc(studentEmail)
            //   .get()
            //   .then((doc) => {
            //   // querySnapshot.docs.map((doc) => confirmedArray.push(doc.data()))
            //   // console.log(querySnapshot.docs)
            //     if (doc.exists) {
            //       confirmed = doc.data().lessonConfirmed;
            //     } else {
            //       confirmed = null;
            //     }
            // })
            // .then(() => {
              let newProperty = {
                cluster: false,
                gender: doc.data().gender,
                firstname: doc.data().firstname,
                lastname: doc.data().lastname,
                photo: doc.data().photo,
                age: doc.data().age,
                focuses: doc.data().focuses,
                bio: doc.data().bio,
                minutes: doc.data().minutes,
                rate: doc.data().rate,
                username: doc.data().username,
                uid: doc.id,
                // lessonConfirmed: confirmed,
              };
              axios
                .get(getUrl(doc.data().address, doc.data().zip))
                .then((res) => {
                  console.log(res.data)
                  let newPoint = {
                    type: "Feature",
                    properties: newProperty,
                    geometry: {
                      type: "Point",
                      coordinates: [
                        res.data.results[0].geometry.location.lat,
                        res.data.results[0].geometry.location.lng,
                      ],
                    },
                  };

                  setMapPoints((prevState) => [...prevState, newPoint]);
                });
            // });
        });
      })
    // }
  }, []);

  const points = mapPoints;
  const mapRef = useRef();
  const btnRef = useRef();
  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;
  // const { clusters, supercluster } = useSupercluster({
  //   points,
  //   zoom: viewport.zoom,
  //   bounds,
  //   options: { radius: 75, maxZoom: 18 },
  // });
  console.log(points)
  console.log(selectedTeacher)
  return (
    <>
        <div className="map-container">
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_TOKEN}}
            defaultCenter={coords}
            defaultZoom={11}
            style={{
              width: 100 + '%'
            }}
            options={{fullscreenControl: false}}
          >
            {points.map((point) => (
              <Avatar 
                key={point.properties.uid}
                lat={point.geometry.coordinates[0]}
                lng={point.geometry.coordinates[1]}
                className={`${classes.small} s-lessons-map-avatar`}
                src={point.properties.photo}
                onClick={() => {
                    setSelectedTeacher(point.properties)
                    handleOpen()
                  }
                }
              >
                {`${point.properties.firstname[0]}${point.properties.lastname[0]}`}
              </Avatar>
            ))}

            {selectedTeacher && (
              <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div 
                className={classes.paper}
                >
                  <div
                    className='modal-header'
                  >
                      <Avatar 
                        src={selectedTeacher.photo}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        {`${selectedTeacher.firstname[0]}${selectedTeacher.lastname[0]}`}
                      </Avatar>
                      <Typography 
                      variant='h3'
                      style={{
                        margin: '0 50px 0 10px'
                      }}
                      >
                        {`${selectedTeacher.firstname} ${selectedTeacher.lastname[0]}.`} 
                      </Typography>
                      <Typography 
                      variant='subtitle1'
                      >
                        {`${selectedTeacher.gender.charAt(0).toUpperCase() + selectedTeacher.gender.slice(1)}, ${selectedTeacher.age}`}
                      </Typography>
                  </div>

                  <div className='modal-body'>
                      <Typography
                      variant='body1'
                      style={{
                        margin: 5 + 'px'
                      }}
                      >
                        {`Focal(s): ${selectedTeacher.focuses}`}
                      </Typography>
                      <Typography
                      variant='body1'
                      style={{
                        margin: 5 + 'px'
                      }}
                      >
                        {`Bio: ${selectedTeacher.bio}`}
                      </Typography>
                      <Typography
                      variant='body1'
                      style={{
                        margin: 5 + 'px'
                      }}
                      >
                        {`Rate(s): $${selectedTeacher.rate}/${selectedTeacher.minutes} min.`}
                      </Typography>
                  </div>

                  <div className='modal-signin'>
                    <Typography
                    variant='subtitle1'
                    >
                      <Link>Sign in</Link> to message instructor
                    </Typography>
                  </div>  

                  <div className='modal-buttons'>
                    <Button
                    disabled
                    type='submit'
                    variant='contained'
                    size='large'
                    >
                      <Typography
                      variant='h6'
                      >
                        Message Instructor
                      </Typography>                
                    </Button>
                  </div>
                </div>
              </Fade>
            </Modal>
            )}
          </GoogleMapReact>
          <Controls/>
        </div>            
    </>
  );
};





// useEffect(() => {
  //   if (firebase.auth().currentUser.email !== null) {
  //     const studentEmail = firebase.auth().currentUser.email;
  //     const studentUid = firebase.auth().currentUser.uid;
  //   db
  //     .collection("StudentInfo")
  //     .doc(studentUid)
  //     .get()
  //     .then((doc) => {
  //       let studentData = {
  //         UID: studentUid,
  //         email: studentEmail,
  //         gender: doc.data().gender,
  //         firstname: doc.data().firstname,
  //         lastname: doc.data().lastname,
  //         age: doc.data().age,
  //         bio: doc.data().bio,
  //         photo: doc.data().photo,
  //         address: doc.data().address,
  //         zip: doc.data().zip
  //       }
  //       console.log(studentData)
  //       setCurrentStudent(studentData)
  //     })
  //   }
  // }, [])










{/* <Card className="s-lessons-main-card-container"> */}
        {/* <Card
          className="s-lessons-inner-card-container"
          id="s-lessons-pending-card-container"
        >
          <CardHeader
            title="Pending Lessons"
            titleTypographyProps={{ variant: "h7", align: "center" }}
          />
          {mapPoints.map((point) => {
            const teacher = point.properties;
            const teachername = teacher.firstname.concat(" ", teacher.lastname);

            if (teacher.lessonConfirmed === false) {
              return (
                <>
                  <div key={uuidv4()} className="s-lessons-avatar-container">
                    <Avatar
                      key={teacher.uid}
                      src={teacher.photo}
                      className={`${classes.default} s-lessons-avatar`}
                      alt={teachername}
                    >
                      {teacher.firstname.slice(0, 1)}
                      {teacher.lastname.slice(0, 1)}
                    </Avatar>
                    <Typography
                      style={{ paddingLeft: 10 + "px", fontSize: 12 + "px" }}
                    >
                      {teachername}
                    </Typography>
                  </div>
                </>
              );
            }
          })}
        </Card> */}
{/* <ReactMapGL
  style={{
    flexBasis: 'auto', 
    alignItems: 'center',
    flexGrow: 1, 
    width: 100+'vw', 
    height: 100+'%'}}
    {...viewport}
    onViewportChange={(nextViewport) => setViewport({ ...nextViewport })}
    maxZoom={12}
    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
    mapStyle="mapbox://styles/kevinwilsongrove/ckg71o5360csu19nt9hpuqudh"
    ref={mapRef}
    >
    <GeolocateControl
    positionOptions={{ enableHighAccuracy: true }}
    trackUserLocation={true}
    showUserLocation={true}
    style={{
      width: 29 + "px",
      justifySelf: "flex-end",
      margin: 5 + "px",
      position: "absolute",
      right: 10 + "px",
      bottom: 25 + 'px'
    }}
    />
    
    {clusters.map((cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const {
        cluster: isCluster,
        point_count: pointCount,
      } = cluster.properties;
      
      if (isCluster) {
        return (
          <Marker
          latitude={latitude}
          longitude={longitude}
          key={uuidv4()}
          >
          <div
          className={`${classes.default} cluster-marker`}
          style={{
            width: `${10 + (pointCount / points.length) * 20}px`,
            height: `${10 + (pointCount / points.length) * 20}px`,
          }}
          onClick={() => {
            const expansionZoom = Math.min(
              supercluster.getClusterExpansionZoom(cluster.id),
              20
              );
              setViewport({
                ...viewport,
                latitude,
                longitude,
                zoom: expansionZoom,
                transitionInterpolator: new FlyToInterpolator({
                  speed: 1,
                }),
                transitionDuration: "auto",
              });
            }}
            >
            {pointCount}
            </div>
            </Marker>
            );
          }
          
          return (
            <Marker
            latitude={latitude}
            longitude={longitude}
            key={cluster.properties.uid}
            >
            <Avatar
            className={`${classes.small} s-lessons-map-avatar`}
            key={uuidv4()}
            onClick={() =>
              setViewport({
                ...viewport,
                latitude,
                longitude,
                zoom: 11.5,
                transitionInterpolator: new FlyToInterpolator({
                  speed: 1,
                }),
                transitionDuration: "auto",
              })
            }
            >
            {/* {cluster.properties.firstname.slice(0, 1)}
          {cluster.properties.lastname.slice(0, 1)} */}
          {/* <Button
            className={classes.small}
            key={uuidv4()}
            onClick={(event) => {
              event.preventDefault();
              setSelectedTeacher(cluster);
              console.log("cluster: ", cluster);
            }}
            >
            {cluster.properties.firstname.slice(0, 1)}
            {cluster.properties.lastname.slice(0, 1)}
            </Button>
            </Avatar>
            </Marker>
            );
          })} */}
          
          {/* {selectedTeacher ? (
            <Popup
            latitude={selectedTeacher.geometry.coordinates[1]}
            longitude={selectedTeacher.geometry.coordinates[0]}
            closeButton={true}
            onClose={() => {
              setSelectedTeacher(null);
            }}
            closeOnClick={false}
            >
            <div>
            <Typography>
            {selectedTeacher.properties.firstname}{" "}
            {selectedTeacher.properties.lastname}
            <br />
            Focuses: {selectedTeacher.properties.focuses}
            <br />
            Rate: ${selectedTeacher.properties.rate}/
            {selectedTeacher.properties.minutes}minutes
            </Typography>
            <br />
            {selectedTeacher.properties.lessonConfirmed === null ? (
              <Button
              variant="contained"
              id="confirmation-button"
              onClick={(event) => {
                event.preventDefault();
                handleLessonConfirmation(selectedTeacher, btnRef, mapPoints, db, studentUid, currentStudent);
              }}
              ref={btnRef}
              >
              Request Lesson
              </Button>
              ) : selectedTeacher.properties.lessonConfirmed === false ? (
                <Button variant="contained" id="confirmation-button" disabled="true" >
                Lesson Request Sent
                </Button>
                ) : (
                  <Button variant="contained" id="confirmation-button" disabled="true" >
                  ✅  Lesson Confirmed ✅
                  </Button>
                  )}
                  </div>
                  </Popup>
                  ) : null}
                </ReactMapGL> */} 
                {/* <Card
                  className="s-lessons-inner-card-container"
                  id="s-lessons-confirmed-card-container"
                >
                  <CardHeader
                    title="Confirmed Lessons"
                    titleTypographyProps={{ variant: "h7", align: "center" }}
                  />
                  {mapPoints.map((point) => {
                    const teacher = point.properties;
                    const teachername = teacher.firstname.concat(" ", teacher.lastname);
                    if (teacher.lessonConfirmed === true) {
                      return (
                        <>
                          <div key={uuidv4()} className="s-lessons-avatar-container">
                            <Avatar
                              key={teacher.uid}
                              src={teacher.photo}
                              className={`${classes.default} s-lessons-avatar`}
                              alt={teachername}
                            >
                              {teacher.firstname.slice(0, 1)}
                              {teacher.lastname.slice(0, 1)}
                            </Avatar>
                            <Typography
                              style={{ paddingLeft: 10 + "px", fontSize: 12 + "px" }}
                            >
                              {teachername}
                            </Typography>
                          </div>
                        </>
                      );
                    }
                  })}
                </Card> */}
                {/* </Card> */}