import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  Icon,
  Avatar,
  Button,
  Divider,
  Typography,
  Backdrop,
  Modal, 
  MenuItem, 
  MenuList,
  Paper,
  Grow,
  ClickAwayListener,
  Popper,
  makeStyles,
  SvgIcon
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import firebase from "../../../fbConfig";
import './Menu.css'
import logoColor from '../../../../images/logo-color.png'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
        
    },
    button: {
        // backgroundColor: '#1a54c4',
        color: 'whitesmoke',
    },
    hover: {}
  }));

export const Menu = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = useRef(null)
    
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
    };
    
    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
          event.preventDefault();
          setOpen(false);
        }
    }

    return (

        <>
            {/* <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                variant='contained'
                style={{
                    margin: '7px 7px 0 0'
                }}
                className={`${classes.button} menu-button`}
            > */}
            <div>
                <img 
                    src={logoColor} 
                    alt="Logo"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={`${classes.button} menu-button`}
                    // style={{
                    //     top: 0,
                    //     right: 0,
                    //     position: "absolute",   
                    //     height: 80 + 'px',
                    //     width: 80 + 'px',
                    //     margin: 5 + 'px'
                    // }}
                />
                <span className='tooltiptext'>Menu</span>
            </div>
            {/* </Button> */}
            <Popper 
                open={open} 
                anchorEl={anchorRef.current} 
                role={undefined} 
                transition 
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ 
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' 
                    }}
                >
                    <Paper className={classes.paper}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList 
                            autoFocusItem={open} 
                            id="menu-list-grow" 
                            onKeyDown={handleListKeyDown}
                        >
                            
                        <MenuItem 
                            onClick={handleClose}
                            className='menu-item'
                        >
                            Profile
                        </MenuItem>

                        <MenuItem 
                            onClick={handleClose}
                            className='menu-item'
                        >
                            My account
                        </MenuItem>

                        <MenuItem 
                            onClick={handleClose}
                            className='menu-item'
                        >
                            Logout
                        </MenuItem>

                        </MenuList>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </>
    )
}

