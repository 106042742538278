export * from "./StudentHome"
export * from "./StudentLessons"
export * from "./StudentLogin"
export * from "./StudentMessages"
export * from "./StudentProfile";
export * from "./StudentRegistration";

export * from "./TeacherHome"
export * from "./TeacherLessons";
export * from "./TeacherLogin"
export * from "./TeacherMessages";
export * from "./TeacherProfile";
export * from "./TeacherRegistration";

export * from "./NotFound";
export * from "./LandingPage"
