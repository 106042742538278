import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  Icon,
  Avatar,
  Button,
  Divider,
  Typography,
  Backdrop,
  Modal, 
  MenuItem, 
  MenuList,
  Paper,
  Grow,
  ClickAwayListener,
  Popper,
  makeStyles,
  SvgIcon
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu'
import firebase from "../fbConfig";
import './Controls.css'
import logo from '../../images/logo.png'
import logoColor from '../../images/logo-color.png'
import { Menu } from './controls/menu/index'
import { Pending } from './controls/pending/index'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
      color: '#1a22b4',
    },
    button: {
        backgroundColor: '#1a22b4',
        color: 'whitesmoke',
    }
  }));

export const Controls = () => {
    return (
        <>
            {/* <img 
            src={logoColor} 
            alt="Logo"
            className='logo'
            /> */}
            <Menu/>
            <Pending />
        </>
    )
}