import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardHeader,
  Icon,
  Avatar,
  Button,
  Divider,
  Typography,
  Backdrop,
  Modal, 
  MenuItem, 
  MenuList,
  Paper,
  Grow,
  ClickAwayListener,
  Popper,
  makeStyles,
  SvgIcon
} from "@material-ui/core";
import firebase from "../../../fbConfig";
import './Pending.css'

export const Pending = () => {
    return (
        <div className="pending-container">
            <div className='pending-header'>
                <Typography>
                    Pending Lessons
                </Typography>
            </div>
        </div>
    )
}